import type { ReactElement } from 'react'

import Head from 'next/head'
import tw from 'twin.macro'

import AppLayout from '@/providers/AppLayout'

export default function ErrorPage() {
  return (
    <>
      <Head>
        <title>PhalaWorld</title>
      </Head>
      <div tw="text-white text-6xl flex flex-row justify-center items-center h-screen w-full">
        Not found
      </div>
    </>
  )
}

ErrorPage.getLayout = (page: ReactElement) => {
  return (
    <AppLayout>
      {page}
    </AppLayout>
  )
}